jQuery.extend({
    
    mNavigation: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder  : _this,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = holder;
                _private.menuButton         = $('.m-navigation__toggle');   
                
                _private.menuButton.bind('click', _private.toggleMenu);
            },
            toggleMenu:function() {
                _private.menuButton.toggleClass('is--active');
                _private.holder.toggleClass('is--active');
            }
        };

        // INITIALIZE APPLICATION
		if($(holder).length > 0) {
			_private.setup();
		}
			// WINDOW RESIZE
		function doResize() {
		}
		var resizeTimer;
		$(window).resize(function() {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(doResize, 100);
		});
	}
});
$(function()
{
	$(document).ready(function()
	{
		var mNavigation = new $.mNavigation($(".m-navigation"));
	});
});